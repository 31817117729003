<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="addRole" v-right-code="'Sysrole:Search'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="saveRole" v-right-code="'Sysrole:Search'">保存</el-button> </div>
            </div>
        </div>
        
        <div class="form-list">
            <el-form :model="dataSource" ref="_roleForm" :rules="roleValidateRule">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>角色名称：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="RoleName">
                            <el-input v-model="dataSource.RoleName" :maxlength="30" :minlength="2" placeholder="角色名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>角色类别：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="PersonType">
							<DictionarySelect v-model="dataSource.PersonType" dicTypeCode="PersonTypeCode" datType="Int" @change="personTypeChange"></DictionarySelect>
						</el-form-item>
                    </el-col>
                    <template v-if="dataSource.PersonType==1">
                        <el-col :span="3" class="form-title">物流组织：</el-col>
                        <el-col :span="7">
                            <el-form-item prop="OrgName">
                                <el-input v-model="dataSource.OrgName" :maxlength="30" :minlength="2" @focus="onOrgNameSelect" suffix-icon="el-icon-search" readonly placeholder="请选择"></el-input>
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-if="dataSource.PersonType==5">
                        <el-col :span="3" class="form-title">
                        承运商：
                        </el-col>
                        <el-col :span="7">
                            <el-form-item prop="CarrierName">
                                <LookPopup v-model="dataSource.CarrierName"
                                    dataTitle="承运商"
                                    dataUrl='omsapi/memberinfo/searchshipprovider'
                                    syncField='MemberName'
                                    @syncFieldEvent="syncMember"
                                ></LookPopup>
                            </el-form-item>
                        </el-col>
                    </template>
                </el-form-item>
                <el-form-item>
                    
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        角色描述：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="RoleDesc">
                            <el-input v-model="dataSource.RoleDesc" :maxlength="500" placeholder="描述" type="textarea"
                                    :autosize="{minRows: 5, maxRows: 20}"></el-input>
                                    <span class="fixed-numer">{{(dataSource.RoleDesc||'').length}}/500</span>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                
                
            </el-form>
        </div>
        <el-tabs type="border-card">
            <el-tab-pane label="租户应用">
                <el-tree :data="dataSource.PlatformPermTree"
                    show-checkbox
                    node-key="ShowSysMenuId"
                    ref="_PlatformPermTree"
                    :default-checked-keys="checkedPlatformPermKeys"
                    :props="treeProps"
                    highlight-current>
                    <span slot-scope="{ node, data }" class="custom-tree-node">
                        <span class="tooltip">{{ data.MenuName }}</span>
                    </span>
                </el-tree>
            </el-tab-pane>
        </el-tabs>

        <DialogEx :options="orgConfig.viewOptions" title="组织机构" @onOk="onOrgControlOk">
			<OrgSelectControl ref="orgControl" :config="orgConfig.data" @onSelectedRow="onOrgControlSelectedRow">
			</OrgSelectControl>
		</DialogEx>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                roleValidateRule: {
                    RoleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' } ],
                    RoleDesc: [ { required: false, message: '请输入角色描述', trigger: 'blur' } ],
                    SysBizType: [{ required: true, message: '请选择角色类型', trigger: 'blur' }],
                },
                tdCount:4,
                sysBizTypeList: [
                    { label: '系统', value: 100 },
                    { label: '业务', value: 200 },
                    { label: '通用', value: 300 }
                ],
                treeProps: {
                    children: 'Children',
                    label: 'MenuName'
                },
                orgConfig:{
                    viewOptions: {
                        visible: false,
                        size: 'small',
                        customClass: 'controlDialog'
                    },
                    data:{

                    },
                    selectedRow:{}
                },

            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            dataSource:{
                RoleOnWarehouseTree:[]
            }
        },
        watch:{
        },
        computed:{
            checkedPlatformPermKeys() {
                return this.dataSource.PlatformPermIds ? this.dataSource.PlatformPermIds.split(";") : [];
            }
        },
        mounted(){
        },
        methods: {
            addRole() {
                this.Event.$emit("onAddRole");
            },
            saveRole() {
                this.$refs["_roleForm"].validate((valid) =>{
                    if (valid) {
                        var obj = this.Utils.cloneObj(this.dataSource);
                        obj.PlatformPermIds = this.$refs._PlatformPermTree.getCheckedKeys(true).join(";");
                        this.$ajax.send("omsapi/sysrole/save", "post", obj, (data) => {
                            this.dataSource.SysRoleID === this.Utils.emptyGuid && (this.dataSource.SysRoleID = data.Result);
                            this.$parent.syncDataSource();
                            this.Event.$emit("reloadRolePageList");
                            this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        this.Utils.messageBox("存在必填信息未输入.", "error");
                        return false;
                    }
                });
            },
            onOrgNameSelect(){
                this.orgConfig.viewOptions.title = "选择公司";
				this.orgConfig.viewOptions.visible = true;
				// this.organizationConfig.type = null;
				// this.organizationConfig.code = 0;
            },
            onOrgControlSelectedRow(row){
                if (row) {
                    this.orgConfig.selectedRow = row;
				}
            },
            onOrgControlOk(){
                var row = this.orgConfig.selectedRow;
                if (row) {
					this.dataSource.OrgId = row.Id;
                    this.dataSource.OrgCode = row.Code;
                    this.dataSource.OrgName = row.Name;
				}
            },
            syncMember:function(row){
                if(row){
                    this.dataSource.CarrierId = row.MemberId;
                    this.dataSource.CarrierCode = row.MemberCode;
                }
            },
            personTypeChange(val){
                if(val==1){
                    this.dataSource.CarrierId = null;
                    this.dataSource.CarrierCode = null;
                    this.dataSource.CarrierName = null;
                }else if(val==5){
                    this.dataSource.OrgId = null;
                    this.dataSource.OrgCode = null;
                    this.dataSource.OrgName = null;
                }
            }
        },
        components: {
			"OrgSelectControl": resolve => {
				require(['@/components/enterprise/components/logorgselector.vue'], resolve)
			},
		}
    }
</script>

<style>
    .t_w {
        width: 100%;
    }

    .t_w tr td {
        padding: 4px 8px;
    }
    .el-dialog.controlDialog{ width: 650px !important; min-height: 400px;}
</style>